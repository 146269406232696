import axios from "axios";
// import { Notification } from "element-react";
import { Storage } from "./Storage";

// create an axios instance
const service = axios.create({
  baseURL: process.env.API_URL, // url = base url + request url
  // withCredentials: true, // send Storages when cross-domain requests
  timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config:any) => {
    config.headers["Authorization"] = "Bearer " + Storage.getSession("Token");
    return config;
  },
  (error:any) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response:any) => {
    const res = response.data;
    if (res.StatusCode === 200 && res.Success) {
      return res;
    } else {
      if (res.StatusCode === 401) {
        window.location.href = "/page401";
      } else {
        alert(res.Message);
      }
    }
  },
  (error:any) => {
    if (error.response.status === 401) {
      window.location.href = "/page401";
    } else {
      alert(error.response.statusText);
    }
  }
);

export default service;
