import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'assets/css/global.css'
import 'assets/scss/portal.scss'
import Layout from './layouts/Layout';

const App = () => {
  let path = window.location.pathname;
  useEffect(() => {
    if(path == "/")
    {
        window.location.href = "trang-chu";
    }
}, [path])
  return (
    <Router>
      <Layout />
    </Router>
  );
};

export default App;
