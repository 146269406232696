import dayjs from 'dayjs';
import { Thang } from "common/Enums";
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const isIterableArray = array => Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================

export const stringL = (name) => {
  if(name.length >= 25){
    name = name.substring(0, 25) + "..."
  }
  return name
}
export const video = (link) =>  {
  let endPoint = link.split("=")[1];
  let newLink = "https://www.youtube.com/embed/" + endPoint
  return newLink
}
export const video_endPoint = (link) =>  {
  let endPoint = link.split("=")[1];
  return endPoint
}
export const num = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
export const date = (date) => {
  let newDate =
    new Date(date).getDate() +
    "/" +
    Thang[new Date(date).getMonth()] +
    "/" +
    new Date(date).getFullYear() +
    " lúc " +
    new Date(date).getHours() +
    ":" +
    new Date(date).getMinutes() +
    " " +
    (new Date(date).getHours() > 12 ? "tối" : "sáng");
  return newDate;
};

export const DateTime = (date, string) => {
  if(string == "day"){
    return new Date(date).getDate().toString();
  }
  if(string == "month"){
    return date ? Thang[new Date(date).getMonth()].toString() : ""
  }
  if(string == "year"){
    return new Date(date).getFullYear().toString()
  }
  if(string == "hour"){
    return new Date(date).getHours().toString()
  }
  if(string == "minute"){
    new Date(date).getMinutes().toString()
  }
  if(string == ""){
    return new Date().toString()
  }
  return "";
}; 
export const day = (date)=> {
  let newDate =
    new Date(date).getDate() +
    "/" +
    Thang[new Date(date).getMonth()] +
    "/" +
    new Date(date).getFullYear()
  return newDate;
};

export const time = (date) => {
  let newDate =
    new Date(date).getHours() +
    ":" +
    new Date(date).getMinutes() +
    " " +
    (new Date(date).getHours() > 12 ? "tối" : "sáng");
  return newDate;
};
export const fileUrl = (url) => {
  if(url){
    let proxy = url.split(":")
    if(proxy[0] == 'http' || proxy[0] == 'https'){
      return url
    }
    return process.env.ROOT_URL + url
  }
  return ""
}
